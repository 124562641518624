import SelectPersonCard from '@components/SelectPersonCard'
import { EmptyImg } from '@constants/index'
import { IVendor } from '@contracts/models/vendor'
import { useGrnPoList } from '@services/grn'
import { useGetJobDetail, useWoList } from '@services/materialEntry'
import { useOperatorsList } from '@services/org'
import { stringIncludes } from '@utils/string'
import { Col, Divider, Row } from 'antd'
import dayjs from 'dayjs'
import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { businessAvatarFour } from 'yolo-design/assets'
import {
  AutoComplete,
  Button,
  FormInput,
  FormInputArea,
  FormSelect,
  Text,
  FormInputDate as YoloDate,
} from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { ShortArrowLeft } from 'yolo-design/icons'

interface IProps {
  vendorData: any
  setSelectVendor: any
  setSearch: any
  selectVendor: number
  dataList: IVendor[]
  grnNumber: string
  form: any
  stockData: any
}

const GrnBasic: FC<IProps> = ({
  setSearch,
  dataList,
  grnNumber,
  form,
  setSelectVendor,
  selectVendor,
  vendorData,
  stockData,
}) => {
  const navigate = useNavigate()
  const { orgId } = useParams()
  const [isEditVendor, setIsEditVendor] = useState(false)
  const [searchWo, setSearchWo] = useState<any>('')
  const [searchParams] = useSearchParams()
  const [jobId, setJobId] = useState()
  const tab: any = searchParams?.get('returnTab')
  const billId = searchParams.get('billId')

  const headingLabel: any = {
    '1': 'Goods Receipt',
    '2': 'Material Inward',
  }
  const shiftData = [
    {
      value: '1',
      label: 'Day',
    },
    {
      value: '2',
      label: 'Night',
    },
    {
      value: '3',
      label: 'None',
    },
  ]
  const { data: woList, isLoading: workListLoading } = useWoList(
    { enabled: tab === '2' },
    {
      search: searchWo,
      ids: billId ? stockData?.work_order?.map((el: any) => el?.id) : '',
    }
  )
  const { data: jobDetails } = useGetJobDetail(
    { enabled: !!jobId },
    { id: jobId as any }
  )
  const { data: operatorsList, isLoading: operatrorListLoading } =
    useOperatorsList({ enabled: tab === '2' })

  const { data: poList, isLoading: poIsloading } = useGrnPoList(
    { enabled: !!Number(selectVendor) },
    {
      vendorId: selectVendor,
      ids: billId
        ? form.getFieldValue('purchase_order')?.join(',') || ''
        : '',
    }
  )

  const handleBack = () => {
    navigate(`/${orgId}/stock-entry?returnTab=${tab || '1'}`)
  }

  useEffect(() => {
    form.setFieldValue('bill_no', grnNumber)
  }, [grnNumber])
  useEffect(() => {
    if (billId) {
      setIsEditVendor(true)
      setJobId(stockData?.job_entity?.[0]),
        form.setFieldsValue({
          work_order: stockData?.work_order?.[0],
          purchase_order:stockData?.purchase_order?.map((el:any) => String(el?.id)),
          operator: stockData?.operator,
        })
    }
  }, [stockData])

  return (
    <div className=" w-100  ">
      <Row gutter={24}>
        <Col span={8}>
          <div className="">
            <div
              onClick={handleBack}
              className="d-flex w-100 h-max-content align-items-center cursor-pointer"
            >
              <ShortArrowLeft
                size="large"
                weight="thick"
                onClick={() => {
                  navigate(`/${orgId}/stock-entry?returnTab=${tab || '1'}`)
                }}
              />
              <Text
                style={{ marginLeft: '12px' }}
                value={headingLabel[tab]}
                category="heading"
                size="small"
                weight="medium"
              />
              <div
                className="spacer"
                style={{
                  width: 'max-content',
                  backgroundColor:
                    stockData?.color_code || Color?.blue?.washed_blue,
                  borderRadius: '4px',
                  padding: '2px 4px',
                  marginLeft: '12px',
                }}
              >
                {stockData?.bill_status_name || 'New'}
              </div>
            </div>
            {tab === '2' ? (
              <>
                <div style={{ marginTop: '20px' }}>
                  {/* <FormSelect
                    title={'Work Order'}
                    placeHolder="Select from Options"
                    name={'work_order'}
                    antdSelectProps={{
                      filterOption: stringIncludes,
                      // allowClear:true,
                      showSearch: true,
                      onSearch: (e) => {
                        
                        setSearchWo(e)
                      },
                     
                      onSelect: (_, column: any) => {
                        console.log('dvd');
                        
                        setJobId(column?.rec?.job_id)
                      },
                    }}
                    required
                    disabled={workListLoading}
                    optionsSelect={
                      woList?.data?.map((el: any) => ({
                        value: String(el?.id),
                        label: el?.work_order_no,
                        rec: el,
                      })) || []
                    }
                  /> */}
                  <FormSelect
                    title={'Work Order'}
                    name={'work_order'}
                    optionsSelect={
                      woList?.data?.map((el: any) => ({
                        value: String(el?.id),
                        label: el?.work_order_no,
                        rec: el,
                      })) || []
                    }
                    required
                    antdSelectProps={{
                      filterOption: stringIncludes,
                      onSearch: (e) => {
                        setSearchWo(e)
                      },

                      onSelect: (_, column) => {
                        setJobId(column?.rec?.job_id)
                      },
                      disabled: workListLoading,

                      showSearch: true,
                    }}
                  />

                  {jobDetails?.id ? (
                    <>
                      <FormInput
                        antdInputProps={{ className: 'd-none' }}
                        andtFormItemProps={{ className: 'd-none' }}
                        name={'job_entity'}
                      />

                      <Text
                        value={'Job Details'}
                        category="body"
                        size="medium"
                        weight="medium"
                      />
                      <div
                        style={{
                          display: 'flex',
                        }}
                        className="border-1 border bg-white border-grey-400 p-8 rounded-4 mt-8"
                      >
                        <div style={{ height: '48px', width: '36px' }}>
                          <img
                            style={{
                              height: '48px',
                              width: '36px',
                              objectFit: 'contain',
                            }}
                            src={jobDetails?.image || EmptyImg}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            marginLeft: '12px',
                            height: '48px',
                          }}
                        >
                          <div>
                            <Text
                              value={jobDetails?.name}
                              category="body"
                              size="medium"
                              weight="medium"
                            />
                          </div>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Text
                                value={'Job Code:'}
                                category="label"
                                size="small"
                                weight="medium"
                                color={Color?.gray_shades?.gray_700}
                              />
                              <Text
                                value={` ${jobDetails?.job_code}`}
                                category="label"
                                size="small"
                                weight="medium"
                                color={Color?.gray_shades?.gray_700}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <>
                {!isEditVendor ? (
                  <div style={{ marginTop: '20px' }}>
                    <Text value="Vendor Name" />
                    <AutoComplete
                      size="large"
                      searchValue="name"
                      style={{
                        width: '100%',
                        marginTop: '10px',
                      }}
                      placeholder={'Select Vendor'}
                      options={[
                        ...(dataList ?? []).map((el: any, i: number) => ({
                          label: (
                            <React.Fragment key={i}>
                              <SelectPersonCard
                                data={el}
                                removeBorder={dataList.length - 1 === i}
                              />
                            </React.Fragment>
                          ),
                          value: String(el?.id),
                        })),
                      ]}
                      onSelect={(value) => {
                        setSelectVendor(value)
                        setIsEditVendor(true)
                        setSearch('')
                      }}
                      onSearch={(val) => setSearch(val)}
                    />
                  </div>
                ) : (
                  <>
                    <div className="w-100">
                      <div className="d-flex mt-20  justify-content-between align-items-center w-100">
                        <div className="d-flex gap-8 align-items-center w-75 p-8">
                          <>
                            <img
                              src={
                                vendorData?.logo
                                  ? vendorData?.logo
                                  : businessAvatarFour
                              }
                              alt="vendor"
                              width={40}
                              height={40}
                              className="rounded-circle overFlow-hidden"
                            />
                          </>

                          <div className=" d-flex w-100  flex-column  ">
                            <div className="text-title-medium text-truncate w-100 fw-semibold">
                              {vendorData?.display_name}
                            </div>
                            <div className="text-label-small text-truncate w-100  text-grey-700">
                              {vendorData?.address_detail?.city}
                            </div>
                            {/* <p>In case address is needed</p> */}
                          </div>
                        </div>
                        <div>
                          <>
                            <Button
                              style={{ padding: 0 }}
                              type="ghost"
                              label={vendorData?.display_name ? 'Change' : ''}
                              antdButtonProps={{
                                onClick: () => {
                                  form.setFieldValue(
                                    'purchase_order',
                                    undefined
                                  )
                                  setIsEditVendor(false), setSelectVendor('')
                                  setSearch('')
                                },
                              }}
                            />
                          </>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="mt-12">
                  <FormSelect
                    optionalTitle="Optional"
                    title={'Purchase Order'}
                    placeHolder="Select from Options"
                    name={'purchase_order'}
                    antdSelectProps={{
                      allowClear: true,
                      filterOption: stringIncludes,
                      mode: 'multiple',
                    }}
                    disabled={poIsloading}
                    optionsSelect={
                      poList?.map((el: any) => ({
                        value: String(el?.id),
                        label: el?.order_number,
                      })) || []
                    }
                  />
                </div>
              </>
            )}
          </div>
        </Col>
        <Col className="m-0">
          <Divider className="m-0" style={{ height: '100%' }} type="vertical" />
        </Col>
        <Col span={15}>
          <Row gutter={24}>
            <Col span={12}>
              <div className="d-flex flex-column  w-100">
                <FormInput
                  antdInputProps={{
                    id: 'new_si_bill_no',
                  }}
                  title={'Bill No'}
                  placeHolder="Bill No"
                  value={grnNumber}
                  name="bill_no"
                  readOnly
                />
                <div className="d-flex justify-content-between w-100 gap-12">
                  <div style={{ width: tab === '1' ? '50%' : '100%' }}>
                    <YoloDate
                      antdDatePickerProps={{
                        format: 'MMMM D, YYYY',
                        disabledDate: (el: any) =>
                          dayjs(el).isAfter(dayjs(), 'day'),
                        onChange: () => {
                          form.setFieldValue('delivery_date')
                        },
                      }}
                      andtFormItemProps={{
                        validateTrigger: 'onChange',
                        initialValue: dayjs(),
                      }}
                      title="Bil Date"
                      name="bill_date"
                      required={true}
                    />
                  </div>
                  {tab === '1' ? (
                    <>
                      <div style={{ width: '50%' }}>
                        <YoloDate
                          title="Received Date"
                          name="delivery_date"
                          required
                          antdDatePickerProps={{
                            format: 'MMMM D, YYYY',
                            disabledDate: (el: any) =>
                              dayjs(form.getFieldValue('bill_date')).isAfter(
                                el,
                                'day'
                              ),
                          }}
                          andtFormItemProps={{
                            validateTrigger: 'onChange',
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {tab === '2' ? (
                  <>
                    <div className="d-flex gap-8 justify-content-between">
                      <div className="w-50">
                        <FormSelect
                          title={'Shift Type'}
                          placeHolder="Select Shift"
                          name={'shift'}
                          antdSelectProps={{
                            filterOption: stringIncludes,
                            className: 'w-100',
                            showSearch: true,
                            allowClear: true,
                            // onChange: (val) => {},
                          }}
                          optionsSelect={shiftData}
                        />
                      </div>
                      <div className="w-50">
                        <FormSelect
                          title={'Operator'}
                          placeHolder="Select Operator"
                          name={'operator'}
                          antdSelectProps={{
                            filterOption: stringIncludes,
                            className: 'w-100',

                            showSearch: true,
                            allowClear: true,
                            // onChange: (val) => {},
                          }}
                          required
                          disabled={operatrorListLoading}
                          optionsSelect={
                            operatorsList?.map((el) => ({
                              value: el.id,
                              label: el.name,
                            })) || []
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex gap-12 justify-content-between">
                      <div className="w-50">
                        <FormInput
                          title="Invoice No"
                          optionalTitle="Optional"
                          placeHolder=""
                          name="reference_no"
                        />
                      </div>
                      <div className="w-50">
                        <YoloDate
                          optionalTitle="Optional"
                          antdDatePickerProps={{
                            style: { width: '100%' },
                            format: 'MMMM D, YYYY',
                          }}
                          title="Invoice Date"
                          name="ref_date"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Col>
            <Col span={12}>
              <div className="d-flex  w-100 flex-column">
                <FormInputArea
                  title={'Notes'}
                  optionalTitle="Optional"
                  name="terms"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default GrnBasic
