import { FC, useRef, useState } from 'react'
import {
  Button,
  Drawer,
  FormInput,
  FormSelect,
  Modal,
  // Modal,
  Table,
  Text,
} from 'yolo-design/components'
import { Add, Edit, Print, Scanner, Setting, Trash } from 'yolo-design/icons'
import { Color } from 'yolo-design/constants'
import { EmptyImg } from '@constants/index'
import { useItemListInfo, usePolyListInfo } from '@services/items'

import { formDecimalInputRestriction } from '@utils/validation'
import { Col, message, Popconfirm, Row } from 'antd'
import ListCard from '@components/ListCard'
import { useSearchParams } from 'react-router-dom'
import { useDeleteEachItem } from '@services/grn'
import BatchDrawer from './Drawer/BatchDrawer'
import BarcodePrint from './Components/BatchCardPrint/BarcodePrint'
import { useReactToPrint } from 'react-to-print'
import { getPort, setPort } from '@utils/auth'
import { RadioList } from 'yolo-design/organisms'
import InwardBarcode from './Components/BatchCardPrint/InwardBarcode'
interface IProps {
  setDataSource: any
  dataSource: any[]
  form: any
  billDate: any
  grnNo: string
  isMR?: boolean
  stockData: any
  stockDetailsRefetch: any
  createGRN: any
  selectVendor: any
  stockDetailsLoading: any
  setItemData: any
  itemData: any
  setError: any
  error: any
}
// interface SerialPort {
//   open: (options: { baudRate: number }) => Promise<void>
//   readable: ReadableStream<Uint8Array> | null
//   writable: WritableStream<Uint8Array> | null
//   close: () => Promise<void>
// }

const GrnDetails: FC<IProps> = ({
  setError,
  error,
  form,
  stockData,
  stockDetailsRefetch,
  createGRN,
  selectVendor,
  stockDetailsLoading,
  setItemData,
  itemData,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>()
  const [portList, setPortList] = useState<any>()
  const [selectPort, setSelectPort] = useState<any>()
  const [searchText, setSearchText] = useState('')
  const [openDrw, setOpenDrw] = useState(false)
  const [selectPrintId, setSelectPrintId] = useState<any>('')
  const printRef: any = useRef(null)

  const [searchParams] = useSearchParams()
  const tab: any = searchParams?.get('returnTab')
  // Api Calls

  const handleGrossChange = (e: any) => {
    const grossQty = e.target.value

    form.setFieldValue('gross_weight', grossQty)

    form.setFieldValue('tare_weight', '')

    form.setFieldValue('net_weight', '')
  }
  const handleNetChange = (e: any) => {
    const netQty = e.target.value
    const grossQty = form.getFieldValue('gross_weight')

    form.setFieldValue('net_weight', netQty)

    if (grossQty) {
      form.setFieldValue(
        'tare_weight',
        Number(parseFloat(grossQty) - parseFloat(netQty || 0)).toFixed(3)
      )
    }
  }
  const handleTarChange = (e: any) => {
    const tarQty = e.target.value
    const grossQty = form.getFieldValue('gross_weight')

    form.setFieldValue('tare_weight', tarQty)
    if (grossQty) {
      form.setFieldValue(
        'net_weight',
        Number(parseFloat(grossQty) - parseFloat(tarQty || 0)).toFixed(3)
      )
    }
  }
  const APICALL: any = {
    1: useItemListInfo,
    2: usePolyListInfo,
  }
  const { data: itemList } = APICALL[tab](
    {},
    { page_size: 30, search: searchText }
  )
  const deleteItem = useDeleteEachItem({
    onSuccess: () => {
      stockDetailsRefetch()

      // delete printRef?.current[]
    },
  })
  const handlePrint = useReactToPrint({
    pageStyle: '@page { size: 80mm 50mm; margin: 0mm;}',

    content: () => printRef.current,
  })
  // Table Functions
  const displayItems: any = () => {
    if (!itemList) return []
    return itemList?.data?.map((el: any, i: any) => ({
      value: String(el?.id),
      label: (
        <ListCard data={el} removeBorder={itemList?.data?.length - 1 === i} />
      ),
      data: el,
    }))
  }
  const handleConnect = async (val: any) => {
    const isPort = getPort()
    if (isPort) {
      // await reconnectSerialPort()

      const url = `https://localhost:2306/api/continuity/serial/read?portName=${isPort}&baudRate=9600`
      try {
        const response = await fetch(url, { method: 'GET' })
        if (!response.ok) {
          throw new Error(`Response status: ${response.status}`)
        }
        const json = await response.json()
        if (String(val) === '1') {
          form.setFieldValue('net_weight', Number(json?.data).toFixed(3))
        } else if (String(val) === '2') {
          form.setFieldValue('gross_weight', Number(json?.data).toFixed(3))
        }
      } catch (error) {
        // console.log('');
      }
    } else {
      const url = 'https://localhost:2306/api/continuity/serial/devices'
      try {
        const response = await fetch(url, { method: 'GET' })
        if (!response.ok) {
          throw new Error(`Response status: ${response.status}`)
        }
        const json = await response.json()
        // const list = [
        //   {
        //     "name": "Arduino Uno (COM3)",
        //     "serialPort": "COM3",
        //     "description": "Arduino Uno",
        //     "manufacturer": "Arduino LLC (www.arduino.cc)",
        //     "deviceId": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "pnpDeviceID": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "baudRate": 9600
        //   },{
        //     "name": "Arduino Uno (COM3)",
        //     "serialPort": "COM4",
        //     "description": "Arduino Uno",
        //     "manufacturer": "Arduino LLC (www.arduino.cc)",
        //     "deviceId": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "pnpDeviceID": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "baudRate": 9600
        //   },{
        //     "name": "Arduino Uno (COM3)",
        //     "serialPort": "COM5",
        //     "description": "Arduino Uno",
        //     "manufacturer": "Arduino LLC (www.arduino.cc)",
        //     "deviceId": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "pnpDeviceID": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "baudRate": 9600
        //   },{
        //     "name": "Arduino Uno (COM3)",
        //     "serialPort": "COM6",
        //     "description": "Arduino Uno",
        //     "manufacturer": "Arduino LLC (www.arduino.cc)",
        //     "deviceId": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "pnpDeviceID": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "baudRate": 9600
        //   },{
        //     "name": "Arduino Uno (COM3)",
        //     "serialPort": "COM7",
        //     "description": "Arduino Uno",
        //     "manufacturer": "Arduino LLC (www.arduino.cc)",
        //     "deviceId": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "pnpDeviceID": "USB\\VID_2341&PID_0043\\95635333630351B07122",
        //     "baudRate": 9600
        //   }
        // ]
        setPortList(json?.devices)
        if (json?.devices?.length) {
          setIsOpen(true)
        }
      } catch (error) {
        message.error('Weight machine Error')
      }
    }
  }
  const defaultColumns: any = [
    {
      ttile: <></>,
      width: '5%',
      render: (_: any, rec: any) => {
        return (
          <>
            <Popconfirm
              placement="leftTop"
              style={{ width: '200px' }}
              okText="Delete"
              cancelText="Cancel"
              okButtonProps={{
                loading: deleteItem?.isLoading,
                style: {
                  borderColor: Color?.indicators?.error,
                  backgroundColor: 'transparent',
                  color: Color?.indicators?.error,
                },
              }}
              title="Are you sure to delete this item?"
              onConfirm={() => {
                deleteItem?.mutate({ id: rec?.id })
              }}
              icon={false}
            >
              <Trash weight="thin" color={Color?.indicators?.error} />
            </Popconfirm>
          </>
        )
      },
    },
    {
      title: 'SL',
      dataIndex: 'number',
      width: '5%',
    },
    {
      title: 'Item Name',
      dataIndex: 'name',
      width: '40%',
      render: (_: any, rec: any) => {
        return (
          <>
            <div
              style={{
                height: '100%',
                display: 'flex',
              }}
            >
              <div style={{ height: '48px', width: '36px' }}>
                <img
                  style={{
                    height: '48px',
                    width: '36px',
                    objectFit: 'contain',
                  }}
                  src={rec?.image || EmptyImg}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  marginLeft: '12px',
                  height: '48px',
                }}
              >
                <div>
                  <Text
                    value={rec?.item}
                    category="body"
                    size="medium"
                    weight="medium"
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      value={'SKU Code :'}
                      category="label"
                      size="small"
                      weight="medium"
                      color={Color?.gray_shades?.gray_700}
                    />
                    <Text
                      value={rec?.sku_code}
                      category="label"
                      size="small"
                      weight="medium"
                      color={Color?.gray_shades?.gray_700}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      },
    },
    {
      title: 'Unit ',
      dataIndex: 'unit',
    },
    {
      title: 'Gross Qty',
      dataIndex: 'gross_quantity',
    },
    {
      title: 'Net Qty',
      dataIndex: 'net_quantity',
    },
    {
      title: 'Tar Qty',
      dataIndex: 'tar_quantity',
    },

    {
      title: <></>,
      width: '5%',
      render: (_: any, rec: any) => {
        const itemDetails = stockData?.item_details
          ?.map((item: any, i: any) => ({ ...item, sl: i + 1 }))
          ?.find((item: any) => String(item?.id) === String(selectPrintId))

        return (
          <>
            {rec?.item ? (
              <>
                <div className="d-flex">
                  <div className="d-none">
                    {tab === '1' ? (
                      <>
                        <BarcodePrint
                          heading={stockData?.branch}
                          isReturn={false}
                          billDate={stockData?.bill_date}
                          itemDetails={itemDetails}
                          elRef={printRef}
                        />
                      </>
                    ) : (
                      <>
                        <InwardBarcode
                          heading={stockData?.branch}
                          isReturn={false}
                          billDate={stockData?.bill_date}
                          itemDetails={itemDetails}
                          elRef={printRef}
                        />
                      </>
                    )}
                  </div>
                  <Button
                    icon={Print}
                    type="ghost"
                    // color={Color?.indicators?.error}
                    antdButtonProps={{
                      // disabled: disabletable,
                      onClick: () => {
                        setSelectPrintId('')
                        setSelectPrintId(rec?.id)
                        setTimeout(() => {
                          handlePrint({})
                        }, 500)
                      },
                    }}
                  />
                  <Button
                    icon={Edit}
                    type="ghost"
                    color={Color?.gray_shades?.gray_700}
                    antdButtonProps={{
                      onClick: () => {
                        setItemData({
                          ...rec,
                          image: rec?.image,
                          name: rec?.item,
                          sku_code: rec?.sku_code,
                          item_units: rec?.item_units,
                        })
                        form.setFieldsValue({
                          item_id: rec?.id,
                          unit: String(rec?.unit_id),
                          gross_weight: rec?.gross_quantity,
                          net_weight: rec?.net_quantity,
                          tare_weight: rec?.tar_quantity,
                          item_notes: rec?.item_notes,
                        })
                      },
                    }}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )
      },
    },
  ]

  const sperateByUnit = () => {
    const unitsId = stockData?.item_details?.map((el: any) => {
      const unitId = el?.item_units?.find(
        (item: any) => String(item?.id) === String(el?.unit_id)
      ).unit_id
      return {
        ...el,
        unitId,
      }
    })

    return unitsId
  }
  const arra = sperateByUnit()
  function aggregateQuantitiesByUnitId(data: any) {
    const output: any = {
      gross_quantity: {},
      net_quantity: {},
      tar_quantity: {},
    }

    data?.forEach((item: any) => {
      const { unitId, unit, gross_quantity, net_quantity, tar_quantity } = item

      // Aggregate gross_quantity by unitId
      if (!output.gross_quantity[unitId]) {
        output.gross_quantity[unitId] = {
          total: 0,
          unit: unit,
        }
      }
      output.gross_quantity[unitId].total += gross_quantity

      // Aggregate net_quantity by unitId
      if (!output.net_quantity[unitId]) {
        output.net_quantity[unitId] = {
          total: 0,
          unit: unit,
        }
      }
      output.net_quantity[unitId].total += net_quantity

      // Aggregate tar_quantity by unitId
      if (!output.tar_quantity[unitId]) {
        output.tar_quantity[unitId] = {
          total: 0,
          unit: unit,
        }
      }
      output.tar_quantity[unitId].total += tar_quantity
    })

    // Format the output to include the unit name
    const formattedOutput: any = {
      gross_quantity: [],
      net_quantity: [],
      tar_quantity: [],
    }

    for (const unitId in output.gross_quantity) {
      formattedOutput.gross_quantity?.push(
        `${Number(output.gross_quantity[unitId].total || 0).toFixed(3)} ${
          output.gross_quantity[unitId].unit
        }`
      )
    }

    for (const unitId in output.net_quantity) {
      formattedOutput.net_quantity?.push(
        `${Number(output.net_quantity[unitId].total || 0).toFixed(3)} ${
          output.net_quantity[unitId].unit
        }`
      )
    }

    for (const unitId in output.tar_quantity) {
      formattedOutput.tar_quantity?.push(
        `${Number(output.tar_quantity[unitId].total || 0).toFixed(3)} ${
          output.tar_quantity[unitId].unit
        }`
      )
    }

    return formattedOutput
  }
  const totalWeight = aggregateQuantitiesByUnitId(arra)

  return (
    <>
      <div>
        <Text
          value={`Item List (${stockData?.item_details?.length || 0})`}
          category={'title'}
          size="large"
          weight="semibold"
          style={{ marginTop: '0px' }}
        />
        <div className="mt-12">
          <Row className="d-flex  align-items-center" gutter={12}>
            <Col span={6}>
              {itemData ? (
                <div>
                  <div className="d-none">
                    <FormInput name={'item_id'} />
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <Text
                      category="label"
                      size="small"
                      className="mt-4"
                      value={'Item'}
                    />
                    {itemData?.item_tracking === 2 ? (
                      <>
                        {itemData?.batch_no ? (
                          <>
                            <Button
                              antdButtonProps={{
                                onClick: () => {
                                  setOpenDrw(true)
                                },
                              }}
                              type="ghost"
                              icon={Setting}
                              label="Manage Batch"
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              antdButtonProps={{
                                onClick: () => {
                                  setOpenDrw(true)
                                },
                              }}
                              type="ghost"
                              icon={Add}
                              label="Add Batch"
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                    }}
                    className="border p-2  rounded-4 mt-8"
                  >
                    <div style={{ height: '38px', width: '36px' }}>
                      <img
                        style={{
                          height: '38px',
                          width: '36px',
                          objectFit: 'contain',
                        }}
                        src={itemData?.image || EmptyImg}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        // marginLeft: '8px',
                        height: '38px',
                        width: '85%',
                      }}
                    >
                      <Text
                        value={itemData?.name}
                        category="body"
                        size="medium"
                        weight="medium"
                        className="text-truncate"
                      />

                      <div style={{ display: 'flex', width: '100%' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Text
                            value={'Sku:'}
                            category="label"
                            size="small"
                            weight="medium"
                            color={Color?.gray_shades?.gray_700}
                            className="text-truncate "
                            // style={{width:'15%'}}
                          />
                          <Text
                            value={itemData?.sku_code}
                            category="label"
                            size="small"
                            weight="medium"
                            color={Color?.gray_shades?.gray_700}
                            className="text-truncate"
                            style={{ width: '75%' }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <FormSelect
                    title={'Item'}
                    showErrorMessage={false}
                    placeHolder="enter name/sku"
                    name={'item'}
                    antdSelectProps={{
                      autoFocus: true,

                      showSearch: true,
                      filterOption: (input, option) => {
                        return (
                          (option?.data?.name ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase()) ||
                          (option?.data?.sku_code ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        )
                      },
                      onSearch: (e) => {
                        setSearchText(e)
                      },
                      onSelect: (_: any, rec) => {
                        const unit_id = rec?.data?.item_units?.find(
                          (el: any) => el?.is_primary === true
                        )?.id
                        setItemData(rec?.data)
                        form.setFieldValue('unit', String(unit_id))
                        setSearchText('')
                      },
                    }}
                    required
                    optionsSelect={displayItems()}
                  />
                </>
              )}
            </Col>
            <Col span={2}>
              <FormSelect
                title={'Unit'}
                showErrorMessage={false}
                name={'unit'}
                antdSelectProps={{
                  bordered: true,
                }}
                optionsSelect={itemData?.item_units?.map((el: any) => ({
                  value: String(el?.id),
                  label: el?.unit,
                }))}
                required
              />
            </Col>
            <Col span={3}>
              <div className="d-flex  flex-column">
                <FormInput
                  andtFormItemProps={{
                    style: { marginTop: error ? '20px' : '' },
                  }}
                  antdInputProps={{
                    onInput: (e) => {
                      formDecimalInputRestriction(e)
                    },

                    onChange: (e) => {
                      setError(false)
                      handleGrossChange(e)
                    },
                  }}
                  name={'gross_weight'}
                  showErrorMessage={false}
                  suffixDetails={{
                    label: <Scanner onClick={() => handleConnect(2)} />,
                  }}
                  onKeyDown={(e) => {
                    if (e.key === ' ') {
                      handleConnect(2)
                    }
                  }}
                  title={
                    <>
                      <Text
                        className="mt-2"
                        category="label"
                        size="small"
                        value={'Gross Qty '}
                      />
                    </>
                  }
                  tooltipContent="Press Space Key to auto read data from Weight Machine"
                  required
                />
                {error ? (
                  <>
                    <Text
                      className="text-label-extra-small"
                      color={Color?.indicators?.error}
                      value="(Net + Tare) qty "
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Col>
            <Col span={2}>
              {' '}
              <FormInput
                antdInputProps={{
                  onInput: (e) => {
                    formDecimalInputRestriction(e)
                  },
                  onChange: (e) => {
                    setError(false)
                    handleNetChange(e)
                  },
                }}
                name={'net_weight'}
                showErrorMessage={false}
                title={'Net Qty'}
                required
              />
            </Col>
            <Col span={2}>
              <FormInput
                antdInputProps={{
                  onInput: (e) => {
                    formDecimalInputRestriction(e)
                  },
                  onChange: (e) => {
                    setError(false)
                    handleTarChange(e)
                  },
                }}
                name={'tare_weight'}
                title="Tare Qty"
                showErrorMessage={false}
                required
              />
            </Col>

            <Col span={3}>
              <FormInput
                optionalTitle="Optional"
                name={'item_notes'}
                title="Notes"
                showErrorMessage={false}
              />
            </Col>
            <Col>
              <div className="d-flex gap-4">
                <Button
                  antdButtonProps={{
                    // loading: createDispatchData?.isLoading,
                    onClick: () => {
                      setSearchText('')
                      setError(false)
                      setItemData(undefined)
                      form.resetFields([
                        'item',
                        'gross_weight',
                        'net_weight',
                        'unit',
                        'tare_weight',
                        'item_notes',
                        'item_id',
                      ])
                    },
                    className: 'mt-32',
                  }}
                  size="medium"
                  type="outlined"
                  color={Color?.indicators.error}
                  borderColor={Color?.indicators?.error}
                  label="Clear"
                />
                <Button
                  antdButtonProps={{
                    loading: createGRN?.isLoading,
                    className: 'mt-32',
                    onClick: () => {
                      if (tab === '1') {
                        if (selectVendor) {
                          form.submit()
                        } else {
                          message.error('Please Select Vendor')
                        }
                      } else {
                        if (form.getFieldValue('work_order')) {
                          form.submit()
                        } else {
                          message.error('Please Select Work Order')
                        }
                      }
                    },
                  }}
                  size="large"
                  backgroundColor={Color?.blue?.washed_blue}
                  color={Color?.blue?.just_blue}
                  label="Add Entry"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {stockData?.item_details?.length ? (
        <>
          <div className="w-100 mt-12">
            <div>
              <Table
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={stockData?.item_details?.map(
                  (item: any, i: any) => {
                    return { ...item, key: i + 1, sl: i + 1 }
                  }
                )}
                columns={defaultColumns}
                loading={stockDetailsLoading}
                rowKey={(record) => record.key}
                summary={() => {
                  return (
                    <tr className="fixed-footer">
                      <td></td>
                      <td>
                        <div className="d-flex">
                          <Text
                            category="label"
                            value="Total:"
                            size="medium"
                            weight="bold"
                          />
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        {totalWeight['gross_quantity']?.map(
                          (col: any, i: any) => {
                            return (
                              <>
                                <Text
                                  key={i}
                                  value={col}
                                  weight="bold"
                                  size="medium"
                                />
                              </>
                            )
                          }
                        )}
                      </td>
                      <td>
                        {totalWeight['net_quantity']?.map(
                          (col: any, i: any) => {
                            return (
                              <>
                                <Text
                                  key={i}
                                  value={col}
                                  weight="bold"
                                  size="medium"
                                />
                              </>
                            )
                          }
                        )}
                      </td>
                      <td>
                        {totalWeight['tar_quantity']?.map(
                          (col: any, i: any) => {
                            return (
                              <>
                                <Text
                                  key={i}
                                  value={col}
                                  weight="bold"
                                  size="medium"
                                />
                              </>
                            )
                          }
                        )}
                      </td>
                      <td></td>
                    </tr>
                  )
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <Drawer
        width={400}
        customHeader={true}
        open={openDrw}
        destroyOnClose={true}
      >
        <BatchDrawer
          setItemData={setItemData}
          itemData={itemData}
          setOpen={setOpenDrw}
        />
      </Drawer>
      <Modal
        cancelButtonDetails={{}}
        okButtonDetails={{}}
        open={portList ? isOpen : false}
        onCancel={() => {
          setIsOpen(false)
        }}
        title={'Select your port'}
      >
        <div className="w-100 ">
          <RadioList
            list={portList?.map((el: any) => {
              return {
                value: el?.serialPort,
                label: el?.name,
              }
            })}
            selectedOption={selectPort}
            setSelectedOption={(el: any) => setSelectPort(String(el))}
          />
          <div className="d-flex mt-12 px-12 justify-content-end gap-3">
            <Button
              antdButtonProps={{
                onClick: () => {
                  setIsOpen(false)
                },
              }}
              type="outlined"
              label="Cancel"
            />

            <Button
              antdButtonProps={{
                onClick: () => {
                  setPort(selectPort)
                  setIsOpen(false)
                },
                disabled: selectPort ? false : true,
              }}
              label="Save"
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default GrnDetails
