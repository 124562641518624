import { FC } from 'react'
// import cn from 'classnames'
import { Divider } from 'antd'
import Barcode from 'react-barcode'
import dayjs from 'dayjs'

type Iprops = {
  heading: any
  billDate?: any
  isReturn: any
  itemDetails?: any
  elRef: any
}
const InwardBarcode: FC<Iprops> = ({
  elRef,
  billDate,
  itemDetails,
  heading,
}) => {
  return (
    <div
      ref={elRef}
      className="d-flex flex-column text-body-small fw-semibold px-8 pb-0"
    >
      <div className="d-flex border-bottom border-dashed justify-content-center">
        <span className="text-center text-body-large">{heading}</span>
      </div>
      <div className="d-flex w-100 justify-content-between">
        <div className="d-flex w-100 text-label-extra-small justify-content-between">
          <span className="text-label-extra-small text-truncate">
            B.No: <b>{itemDetails?.barcode}</b>
          </span>
        </div>
        <div className="d-flex w-100 justify-content-between">
          <span className="text-label-extra-small w-100 text-truncate">
            <b>{dayjs(billDate)?.format('DD-MM-YY/hh:MM A') || '-'}</b>
          </span>
        </div>
      </div>
      <div className="d-flex w-100 justify-content-between">
        <div className="d-flex w-100 text-label-extra-small justify-content-between">
          <span className="text-label-extra-small text-truncate">
            Job No: <b>{itemDetails?.job_code}</b>
          </span>
        </div>
        <div className="d-flex border border-grey-500 ps-4 border-start border-top-0 border-bottom-0 border-end-0 w-100 justify-content-between">
          <span className="text-label-extra-small w-100 text-truncate">
            Name: <b>{itemDetails?.job_name}</b>
          </span>
        </div>
      </div>
      <div className="d-flex w-100 justify-content-between">
        <span className="text-label-extra-small w-100 text-truncate">
          Work: <b>{itemDetails?.work_order_no}</b>
        </span>
      </div>
      <Divider className="my-0 border-black" dashed />
      <div className="d-flex w-100 justify-content-between">
        <div className="d-flex w-100 text-label-extra-small justify-content-between">
          <span className="text-label-extra-small text-truncate">
            Micron: <b>{itemDetails?.micron}</b>
          </span>
        </div>
        <div className="d-flex w-100 justify-content-between">
          <span className="text-label-extra-small w-100 text-truncate">
            Size: <b>{itemDetails?.material_size}</b>
          </span>
        </div>
      </div>
      <div className="d-flex w-100 justify-content-between">
        <div className="d-flex w-100 text-label-extra-small justify-content-between">
          <span className="text-label-extra-small text-truncate">
            Material: <b>{itemDetails?.item}</b>
          </span>
        </div>
        <div className="d-flex w-100 justify-content-between">
          <span className="text-label-extra-small w-100 text-truncate">
            GSM: <b>{itemDetails?.gsm}</b>
          </span>
        </div>
      </div>
      <Divider className="my-0 border-black" dashed />
      <div className="d-flex justify-content-between">
        <div className="d-flex w-100 justify-content-center">
          {itemDetails?.barcode && (
            <Barcode
              displayValue={false}
              width={1}
              height={40}
              value={itemDetails?.barcode}
            />
          )}
        </div>
        <div className="d-flex flex-column w-100 ">
          <div className="d-flex w-100 justify-content-between">
            <span className="text-label-extra-small w-100 text-truncate">
              Roll No. <b>{itemDetails?.number}</b>
            </span>
          </div>
          <div className="d-flex w-100 text-label-extra-small justify-content-between">
            <span className="text-label-extra-small text-truncate">
              Total: <b>{`${itemDetails?.net_quantity || '-'}${'kg'}`}</b>
            </span>
          </div>
          <div className="d-flex w-100 justify-content-between">
            <span className="text-label-extra-small w-100 text-truncate">
              Opr. <b>{itemDetails?.operator_name}</b>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InwardBarcode
